<template>
    <div
        class="relative block border-2 border-transparent"
        :class="{ ' border-red-700': edit }">
        <!-- Snippet -->
        <div
            class="snippet-content"
            v-html="snippet.calculated_value" />

        <a
            v-if="edit && $page.props.menus.snippets"
            class="absolute left-[-2px] top-[-36px] whitespace-nowrap border-2 border-red-700 bg-red-100 p-1"
            @click="editingModal = true">
            Name: {{ snippet.name }} <FontAwesomeIcon icon="fa-regular fa-pen-to-square" />
        </a>

        <a
            v-if="edit && !$page.props.menus.snippets"
            :href="route('snippets.edit', snippet.id)"
            class="absolute left-[-2px] top-[-36px] whitespace-nowrap border-2 border-red-700 bg-red-100 p-1">
            Name: {{ snippet.name }} <FontAwesomeIcon icon="fa-regular fa-pen-to-square" />
        </a>

        <ModalDialog
            :show="editingModal"
            @close="editingModal = false">
            <template #title> Update {{ snippet.name }} Snippet </template>

            <template #content>
                <div class="flex flex-col">
                    <InputLabel
                        for="value"
                        value="Snippet Content" />
                    <WYSIWYGInput
                        v-model="form.value"
                        class="mt-2" />
                    <InputError
                        :message="form.errors.value"
                        class="mt-2" />
                </div>
            </template>

            <template #footer>
                <SecondaryButton @click="editingModal = false"> Discard Changes </SecondaryButton>

                <PrimaryButton
                    class="ml-2"
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                    @click="updateSnippet">
                    Update Snippet
                </PrimaryButton>
            </template>
        </ModalDialog>
    </div>
</template>

<script setup lang="ts">
import { useForm } from "@inertiajs/inertia-vue3"
import { ref, onMounted } from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import SecondaryButton from "@/Components/Inputs/ButtonSecondary.vue"
import PrimaryButton from "@/Components/Inputs/ButtonPrimary.vue"
import WYSIWYGInput from "@/Components/Inputs/InputWYSIWYG.vue"
import ModalDialog from "@/Components/Modals/ModalDialog.vue"
import InputLabel from "@/Components/Inputs/InputLabel.vue"
import InputError from "@/Components/Inputs/InputError.vue"

const props = defineProps({
  "snippet": null
})

// When true the snippet will have a border around it
let edit = ref(false)

// When true the edit modal will display
let editingModal = ref(false)

onMounted(() => {
    window.addEventListener("keydown", handleEditing)
})

const form = useForm({
    key: props.snippet.key,
    value: props.snippet.value,
    name: props.snippet.name,
    refresh: true,
})

const updateSnippet = () => {
    form.patch(route("snippets.update", { snippet: props.snippet.id }), {
        preserveScroll: true,
        onSuccess: () => (editingModal.value = false),
    })
}

// Allow editing when the ` and shift key are pressed at the same time
const handleEditing = (e) => {
    if (e.shiftKey && e.key === "~") {
        edit.value = !edit.value
    }
}
</script>

<style scoped></style>
